import type { NavItem } from '@hubcms/domain-navigation';
import type { ReactElement } from 'react';

import cx from 'classnames';

import { Center } from '@hubcms/ui-center';

import { NavigationLink, NavigationLogo } from '@hubcms/ui-navigation-2';

import styles from './header.module.scss';

type HeaderDesktopTopicsProps = {
  logoPosition?: 'center' | 'left';
  navigationItems: NavItem[];
  salesElement?: ReactElement;
};

function HeaderDesktopTopics({ logoPosition, navigationItems, salesElement }: HeaderDesktopTopicsProps) {
  const Layout = logoPosition === 'center' ? TopicsLayoutCenter : TopicsLayoutLeft;

  return (
    <Center
      className={styles.topics}
      data-testid="header-desktop-topics"
      maxInlineSize="min(var(--grid-wrap-fluid), var(--max-content-width))"
    >
      <Layout
        nav={
          <nav className={styles.navigationItems}>
            {navigationItems.map(navigationItem => (
              <NavigationLink key={navigationItem.uniqueId} href={navigationItem.url} size="md">
                {navigationItem.text}
              </NavigationLink>
            ))}
          </nav>
        }
        sales={salesElement}
      />
    </Center>
  );
}

type HeaderDesktopTopicsLayoutProps = {
  className?: string;
  nav: ReactElement;
  sales?: ReactElement;
};

function TopicsLayoutCenter({ className, nav, sales }: HeaderDesktopTopicsLayoutProps) {
  return (
    <div className={cx(className, styles.center)}>
      <div className={styles.navigation}>{nav}</div>

      <NavigationLogo size="lg" />

      <div className={styles.sales}>{sales}</div>
    </div>
  );
}

function TopicsLayoutLeft({ className, nav, sales }: HeaderDesktopTopicsLayoutProps) {
  return (
    <div className={cx(className, styles.left)}>
      <div className={styles.navigation}>
        <NavigationLogo size="md" />

        {nav}
      </div>

      <div className={styles.sales}>{sales}</div>
    </div>
  );
}

export default HeaderDesktopTopics;
