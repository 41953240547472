import type { NavItem } from '@hubcms/domain-navigation';
import { useRef } from 'react';
import { useEventListener } from 'usehooks-ts';
import { useNavigation } from '@hubcms/data-access-navigation';
import { EPaperLink, NavigationFocusBlock, NavigationIcon, NavigationLink } from '@hubcms/ui-navigation-2';
import { IconButton } from '@mediahuis/chameleon-react';
import { ePaperLinkLabel, ePaperLinkDescription, ePaperLinkHref, ePaperLinkHrefTarget, ePaperLinkImage } from '@hubcms/brand';

import styles from './aside-menu.module.scss';

type AsideMenuProps = {
  focusItems: NavItem[];
  serviceItems: NavItem[];
};

function AsideMenu({ focusItems, serviceItems }: AsideMenuProps) {
  const { isAsideMenuOpen, toggleIsAsideMenuOpen } = useNavigation();

  const documentRef = useRef(typeof document !== 'undefined' ? document : null);

  const keydownEventListener = (event: KeyboardEvent) => {
    if (event.key === 'Escape' && isAsideMenuOpen) {
      toggleIsAsideMenuOpen();
    }
  };

  useEventListener('keydown', keydownEventListener, documentRef);

  if (isAsideMenuOpen) {
    return (
      <div className={styles.asideMenu}>
        <div className={styles.content}>
          <div className={styles.searchBar}>
            <span className={styles.closeButton}>
              <IconButton
                aria-label="Menu"
                data-testid="navigation-menu-close"
                icon={<NavigationIcon name="menu-close" />}
                onClick={toggleIsAsideMenuOpen}
              />
            </span>

            <p>SEARCH</p>
          </div>

          <div className={styles.focus}>
            {focusItems.map(focusItem => (
              <NavigationFocusBlock
                // https://mediahuis.atlassian.net/browse/CW-2625
                // NOT CLEAR YET HOW CAPTIONS WILL BE MAPPED FROM CUE
                // caption="???"
                href={focusItem.url || '/'}
                key={focusItem.uniqueId}
                label={focusItem.text}
              />
            ))}
          </div>

          {(ePaperLinkLabel || ePaperLinkDescription) && (
            <EPaperLink
              label={ePaperLinkLabel}
              description={ePaperLinkDescription}
              href={ePaperLinkHref}
              target={ePaperLinkHrefTarget}
              imageSrc={ePaperLinkImage?.src}
            />
          )}

          <div className={styles.services}>
            {serviceItems.map(serviceItem => (
              <NavigationLink href={serviceItem.url} key={serviceItem.uniqueId}>
                {serviceItem.text}
              </NavigationLink>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return null;
}

export default AsideMenu;
