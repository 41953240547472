import { loginLabel } from '@hubcms/brand';
import { Button, IconButton } from '@mediahuis/chameleon-react';
import { Person } from '@mediahuis/chameleon-theme-wl/icons';

import styles from './navigation-user-menu.module.scss';

type NavigationUserMenuUnauthenticatedProps = {
  size?: 'sm' | 'lg';
  onLogin: () => void;
};

function NavigationUserMenuUnauthenticated({ size = 'lg', onLogin }: NavigationUserMenuUnauthenticatedProps) {
  if (size === 'sm') {
    return (
      <IconButton
        aria-label={loginLabel}
        className={styles.navigationUserMenuButton}
        data-testid="navigation-usermenu-login"
        icon={Person}
        size="sm"
        onClick={onLogin}
      />
    );
  }

  return (
    <Button
      className={styles.navigationUserMenuButton}
      data-testid="navigation-usermenu-login"
      iconLeft={Person}
      size="sm"
      onClick={onLogin}
    >
      {loginLabel}
    </Button>
  );
}

export default NavigationUserMenuUnauthenticated;
