import type { PropsWithChildren } from 'react';

import cx from 'classnames';

import { LinkText } from '@mediahuis/chameleon-react';

import styles from './navigation-link.module.scss';

type NavigationLink = {
  href?: string;
  size?: 'sm' | 'md' | 'lg';
};

function NavigationLink({ children, href = '/', size = 'sm' }: PropsWithChildren<NavigationLink>) {
  const sizeClass = cx({
    [styles.sm]: size === 'sm',
    [styles.md]: size === 'md',
    [styles.lg]: size === 'lg',
  });

  return (
    <LinkText className={cx(styles.navigationLink, sizeClass)} href={href}>
      {children}
    </LinkText>
  );
}

export default NavigationLink;
