import { Paragraph } from '@mediahuis/chameleon-react';

import styles from './navigation-focus-block.module.scss';

type NavigationFocusBlockProps = {
  caption?: string;
  href: string;
  label: string;
};

function NavigationFocusBlock({ caption, href, label }: NavigationFocusBlockProps) {
  return (
    <a className={styles.navigationFocusBlock} href={href}>
      <Paragraph className={styles.caption}>{caption}</Paragraph>
      <Paragraph className={styles.label}>{label}</Paragraph>
    </a>
  );
}

export default NavigationFocusBlock;
