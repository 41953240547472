import { navigationConfig } from '@hubcms/brand';
import { Logo } from '@mediahuis/chameleon-react';

import styles from './navigation-logo.module.scss';

type NavigationLogoConfig = {
  height: string;
  name: string;
};
type NavigationLogoSize = 'sm' | 'md' | 'lg';

const logoConfig: Record<NavigationLogoSize, NavigationLogoConfig> = {
  sm: {
    height: 'var(--scale-9)',
    name: navigationConfig.logoSmall,
  },
  md: {
    height: 'var(--scale-10)',
    name: navigationConfig.logoSmall,
  },
  lg: {
    height: 'var(--scale-11)',
    name: navigationConfig.logo,
  },
};

type NavigationLogoProps = {
  size?: NavigationLogoSize;
};

function NavigationLogo({ size = 'md' }: NavigationLogoProps) {
  const config = logoConfig[size];

  return (
    <a data-testid="navigation-logo" href="/" style={{ height: config.height }}>
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <Logo className={styles.navigationLogo} name={config.name} />
    </a>
  );
}

export default NavigationLogo;
