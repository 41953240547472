/* eslint-disable max-lines */
import { dateTimeDateFormat, dateTimeTimeFormat } from '@mediahuis/chameleon-theme-wl/tokens';
import { AuthMethod } from '@hubcms/domain-auth';
import { GtmCode } from '@hubcms/domain-tracking';
import { PageType } from '@hubcms/domain-page';
import { ImageFormat } from '@hubcms/domain-images';
import { CustomConsentType } from '@hubcms/domain-custom-consent';
import { TeaserLabelPropConfig } from '@hubcms/domain-teaser';
import {
  EPaperLinkDescription,
  EPaperLinkHref,
  EPaperLinkHrefTarget,
  EPaperLinkImage,
  EPaperLinkLabel,
  NavigationConfig,
} from '@hubcms/domain-navigation';
import { ArticleDropcapPosition } from '@hubcms/domain-article';
import { HeadingData, StoryElementType } from '@hubcms/domain-story-elements';
import { BrandedHeading } from '@mediahuis/chameleon-react';

export { enGB as dateFnsLocale } from 'date-fns/locale/en-GB';
export const locale = 'en-GB';
export const timeZone = 'Europe/London';
export const archiveTitle = 'Archive';
export const gtmCodes: GtmCode[] = [];
export const brandCode = 'wl';
export const adunitBrandCode = 'hub';
export const b2bUrlBrandCode = 'hub';
export const b2bDMPMetaTagBrandCode = 'hub';
export const isKachingNewAdKeyFormat = false;
export const footerLogo = 'brand-main';
export const footerCopyrightLogoUrl = 'mediahuis-currentColor.svg#logo';
export const headerLogo = 'brand-main';
export const headerDateFormat = 'd MMMM yyyy';
export const navigationVersion: 'v1' | 'v2' = 'v1';
export const isLeaderboardAdHiddenOnMobile = false;
export const isHeroBlockMailIconInverted = false;
export const heroBlockSocialIconSize = 'sm';
export const isHeroBlockSocialColored = false;
export const hasDividerInResultListLg = true;
export const loginLabel = 'Login';
export const logoutLabel = 'Logout';
export const authorPrefix = 'author/';
export const searchPrefix = 'search/';
export const tagsPrefix = 'tags/';
export const tagsb2bPrefix = 'sponsored/';
export const logoSlug = 'brand-square-main';
export const logoVersion = '4.8.0';
export const authMethod: AuthMethod = 'none';
export const weatherSectionPage = '/weather/';
export const cimIdentifier = '';
export const cimLanguage = '';
export const buttonAppearanceMapper = {
  primary: 'primary',
  secondary: 'secondary',
  outline: 'tertiary',
  plain: 'default',
};
export const legacyArticlesRedirectPathMatch: null | RegExp = null;

export const navigationConfig: NavigationConfig = {
  logo: 'brand-main',
  logoSmall: 'brand-square-main',
  menuCloseLabel: 'Close menu',
  menuLabel: 'Menu',
};
export const articleLayoutConfig = {
  heroblockPremiumIconLocation: 'label',
  heroBlockLocation: 'belowMedia',
  introLocation: 'aboveMedia',
  newsArticleImageClass: 'fullWidthSm',
  featureArticleImageClass: 'contentWidthBleed',
  opinionArticleImageClass: 'fullWidth',
  sponsoredArticleImageClass: 'fullWidthSm',
  extraPageImageClass: 'fullWidthSm',
  hasDivider: false,
}; // articleV1 param

export const defaultTeaserImageRatio: ImageFormat = 'sixteenNine';
export const sponsoredContentTeaserIsHighlighted = true;
export const tickarooClientId = '635a927785885b5472797873';
export const customConsent: CustomConsentType = '';
export const enableMySection = false;
export const mySectionCtaTextSize: 'sm' | 'md' | 'lg' = 'sm';
export const MySectionHeader = BrandedHeading;
export const mySectionHeaderSize: HeadingData['size'] = 'lg';
export const hasAdsAbovePaywalledArticleHero = true; // articleV1 param
export const enablePersonalizedArticleList = false;
export const weatherIconSize = 'sm';
export const evolvingStoryTeaserHasImage = true;
export const breakingTeaserPremiumIconName = 'brand-plus-main';
export const evolvingTeaserPremiumIconName = 'brand-plus-main';
export const themesWithAlternativePremiumLogo: string[] = [];
export const isSubscriptionMenuEnabled = true;
export const isTopicsMenuEnabled = true;

export const defaultDateFormatToday = dateTimeTimeFormat;
export const defaultDateFormat = dateTimeDateFormat;
export const listTeaserDateFormat: string | undefined = undefined;

export const heroBlockDateShowUpdateLabel = false; // articleV1 param
export const heroBlockDateUpdateLabel = 'update:'; // articleV1 param
export const canDisplayLocationInIntro = false; // articleV1 param
export const articleDropcap: Record<string, ArticleDropcapPosition> = {
  default: 'none',
  'opinion-article': 'first_paragraph',
}; // articleV1 param
export const heroBlockIntroProps = {
  size: 'lg',
  weight: 'default',
};
export const heroBlockHeadingProps = {
  size: '2xl',
};
export const paragraphProps = {
  size: 'lg',
};
export const tagPageHeadingProps = {
  size: '2xl',
};
export const imageCaptionProps = {
  font: 'default',
  size: 'sm',
};

export const hasLabelOnEvolvingStories = false;

export const teaserLabelPropConfig: TeaserLabelPropConfig = {
  default: 'label',
};
export const isTeaserDateShownConfig = new Map<PageType | 'default', boolean>([['default', true]]);
export const isAutomatedTeaserIntroShown = true;
export const enableArticleTagList = true;
export const teaserPremiumIconPosition: 'label' | 'title' = 'label';

export const sectionPageAreaOnePosition = 'left';
export const sectionPageAreaOneTeasertype = 'default';
export const sectionPageAreaTwoLayoutMobileApp = 'stacked';

export const ePaperLinkLabel: EPaperLinkLabel | undefined = undefined;
export const ePaperLinkDescription: EPaperLinkDescription | undefined = undefined;
export const ePaperLinkImage: EPaperLinkImage | undefined = undefined;
export const ePaperLinkHref: EPaperLinkHref | undefined = undefined;
export const ePaperLinkHrefTarget: EPaperLinkHrefTarget = '_blank';

export const showLabelOnEditorialArticle = true; // articleV1 param
export const showLabelOnSponsoredArticle = true; // articleV1 param
export const showSubLabelOnEditorialArticle = true; // articleV1 param
export const showSubLabelOnSponsoredArticle = true; // articleV1 param

export const searchTermQuotationMark = false;

export const brandedHeaderSublinkIcon = null;

export const infoblockHeadingSize: Record<'heading' | 'subHeading', HeadingData['size']> = {
  heading: 'lg',
  subHeading: 'md',
};
export const hasPullQuoteIcon = false;
export const hasPullQuoteQuotationMarks = true;
export const domain = '';

export const articleCommentsType: 'none' | 'coral_talk' = 'none';
export const cacheTimeouts = {
  mostRead: {
    ttlInMs: { ok: 2 * 60 * 1000, error: 15 * 1000, staleTTL: 2 * 60 * 60 * 1000 }, // OK: 2 minutes, Error: 15 seconds, Stale: 2 hours
    backgroundRefetch: 30 * 1000, // 30 seconds before the TTL expires
  },
  weather: {
    ttlInMs: { ok: 10 * 60 * 1000, error: 15 * 1000, staleTTL: 24 * 60 * 60 * 1000 }, // OK: 10 minutes, Error: 15 seconds, Stale: 24 hours
    backgroundRefetch: 30 * 1000, // 30 seconds before the TTL expires
  },
  nonFunctional: {
    ttlInMs: { ok: 2 * 60 * 1000, error: 1 * 60 * 1000, staleTTL: 5 * 1000 }, // OK: 2 minutes, Error: 1 minute, Stale: 5 seconds
    backgroundRefetch: 5 * 1000, // 5 seconds before the TTL expires
  },
  grapeshot: {
    ttlInMs: { ok: 5 * 60 * 1000, error: 2 * 60 * 1000 },
  },
  rssArticleList: {
    ttlInMs: { ok: 60 * 60 * 1000, error: 15 * 1000, staleTTL: 2 * 60 * 60 * 1000 }, // OK: 60 minutes, Error: 15 seconds, Stale: 2 hours
    backgroundRefetch: 30 * 1000, // 30 seconds before the TTL expires  },
  },
};
export const storyElementsToTakeIntoAccountForAds: StoryElementType[] = [];
export const subscriptionButtonAppearance = 'primary';
export const enableGrapeshot = false;
export const ratingElementSize = 'lg';
export const articleLayoutVersion: 'v1' | 'v2' = 'v2'; // articleV1 param
// Domains are hard-coded values for now
// Follow up on https://jira.mediahuis.nl/browse/CW-2139
export const excludedRelationDomains = [
  // 'aachener-zeitung.de',
  // 'contacto.lu',
  // 'gooieneemlander.nl',
  // 'gva.be',
  // 'haarlemsdagblad.nl',
  // 'hbvl.be',
  // 'ijmuidercourant.nl',
  // 'leidschdagblad.nl',
  // 'luxtimes.lu',
  // 'noordhollandsdagblad.nl',
  // 'virgule.lu',
  // 'wort.lu',
];
export const removeStoryblockTopPaddingForTheme = false;
